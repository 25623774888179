
    @import "config.scss";
 
.container {
  width: 100vw;
  height: 86vh;
  top: 0;
  left: 0;
  position: absolute;
  z-index: var(--z-modal);
}
