
    @import "config.scss";
 
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    border-radius: 8px;
  }
  .pages {
    background: var(--color-background-container);
    margin-top: 55px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    .page {
      background: transparent;
      cursor: pointer;
      width: 45px;
      height: 50px;
    }
    .chevron {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
